.inputFormControl {
  border: none;
  width: 100%;
  background: url(https://www.pinclipart.com/picdir/middle/6-62628_calendar-icon-png-clipart-computer-icons-clip-art.png)
    no-repeat;
  background-position: right;
  background-size: 10%;
  opacity: 0.8;
}
.calender {
  position: fixed !important;
  top: 20% !important ;
  left: 40% !important;
  /* right: 0px !important; */
  background: transparent !important;
  /* height: 100% !important ; */
  /* width: 0px !important ; */
}
.calender .calendar-wrapper {
  z-index: 99999 !important;
  padding: 1rem;
  position: relative;
  right: 40%;
  left: 0%;
  top: 20%;
  background: #fff none repeat scroll 0 0 !important;
  width: 300px !important;
  border: 1px solid #a9a9a9;
  /* box-shadow: 5px 3px 8px 2px rgba(0, 0, 0, 0.21) !important; */
}

.nepali-date-picker .calender td.month-day.current:hover {
  cursor: pointer !important;
  background-color: #2096f5;
  border-radius: 50%;
  color: white;
}

.inputFormControl:focus {
  display: none !important;
}
/* .nepali-date-picker .calender .calendar-controller .control .drop-down {
 width: 80px !important;
} */
.nepali-date-picker .calender .calendar-controller .control.month {
  width: 90px !important;
}
.nepali-date-picker .calender table td {
  width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  min-height: 30px !important;
  padding: 0 !important;
}
.nepali-date-picker {
  box-shadow: none !important;
}
