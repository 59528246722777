@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* #wrapper {
  position: relative;
  background: #333;
  height: 100%;
}

.profile-main-loader {
  left: 50% !important;
  margin-left: -100px;
  position: fixed !important;
  top: 50% !important;
  margin-top: -100px;
  width: 45px;
  z-index: 9000 !important;
} */

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 20px;
  height: 20px;
  font-size: 15px;
}
.profile-main-loader .loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150, 200;
  stroke-width: 5px;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: white;
  }
  40% {
    stroke: white;
  }
  66% {
    stroke: white;
  }
  80%,
  90% {
    stroke: white;
  }
}
@keyframes color {
  0% {
    stroke: white;
  }
  40% {
    stroke: white;
  }
  66% {
    stroke: white;
  }
  80%,
  90% {
    stroke: white;
  }
}

.inputFormControl {
  border: none;
  width: 100%;
  background: url(https://www.pinclipart.com/picdir/middle/6-62628_calendar-icon-png-clipart-computer-icons-clip-art.png)
    no-repeat;
  background-position: right;
  background-size: 10%;
  opacity: 0.8;
}
.calender {
  position: fixed !important;
  top: 20% !important ;
  left: 40% !important;
  /* right: 0px !important; */
  background: transparent !important;
  /* height: 100% !important ; */
  /* width: 0px !important ; */
}
.calender .calendar-wrapper {
  z-index: 99999 !important;
  padding: 1rem;
  position: relative;
  right: 40%;
  left: 0%;
  top: 20%;
  background: #fff none repeat scroll 0 0 !important;
  width: 300px !important;
  border: 1px solid #a9a9a9;
  /* box-shadow: 5px 3px 8px 2px rgba(0, 0, 0, 0.21) !important; */
}

.nepali-date-picker .calender td.month-day.current:hover {
  cursor: pointer !important;
  background-color: #2096f5;
  border-radius: 50%;
  color: white;
}

.inputFormControl:focus {
  display: none !important;
}
/* .nepali-date-picker .calender .calendar-controller .control .drop-down {
 width: 80px !important;
} */
.nepali-date-picker .calender .calendar-controller .control.month {
  width: 90px !important;
}
.nepali-date-picker .calender table td {
  width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  min-height: 30px !important;
  padding: 0 !important;
}
.nepali-date-picker {
  box-shadow: none !important;
}

body {
  margin-bottom: 0;
}

html {
  min-height: 100%;
}

body {
  /* background: linear-gradient(180deg, #fff 300px, rgba(19, 46, 152, 0.64) 100%); */
}

/* 
   By default we have body bottom margin 
   for footer, remove for student dashboard 
*/

body {
  margin-bottom: 0px !important;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 2px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  /* padding-top: 12px; */
  /* padding-bottom: 12px; */
  text-align: left;
  background-color: #242221;
  color: white;
}

/* @page {
  size: 220mm 140mm;
} */

/* @media print {
  @page {
    size: a5 potrait;
    margin: 0mm !important;
  }

  @media all {
    .pagebreak {
      overflow: visible;
    }
  }
} */
/* By default we have body bottom margin 
   for footer, remove for student dashboard 
*/

body {
  margin: 0 !important;
}

/* Import Poppins font  */
/* Import Montserrat font */

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f6 !important;
  margin: 0 0 40px 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#dateInputGroup {
  display: inherit;
}

div#dateInputGroup:hover {
  border-color: 1px olid red;
}

button#resetDateButton {
  margin-top: 8px;
  height: 35px;
  margin-left: -6px;
  color: rgb(102, 100, 100);
}

/* Scrollbar */

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 4px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars. 
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.4); /* Some darker color when you click it */
  border-radius: 100px;
}

/* Input type color CSS modified */
/* .input-color-container {
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border: solid 2px #ddd;
  border-radius: 40px;
}

.input-color {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 56px;
  height: 56px;
  border: none;
}

.input-color-label {
  cursor: pointer;
  text-decoration: underline;
  color: #3498db;
} */

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 45px;
  height: 32px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: 1px solid rgb(180, 177, 177);
}
input[type="text"].editable {
  background-color: #e0dedd;
  border: none;
  width: 100px;
  transition: width 0.35s ease-in-out;
}
input[type="text"].editable:focus {
  outline: none;
  border: 1px dotted black;
}

input[type="number"].editable-cell {
  padding: 7px 10px;
  margin: 8px 0;
  border: 1px solid rgb(157, 157, 157);
}

input[type="number"]:focus.editable-cell {
  padding: 7px 10px;
  margin: 8px 0;
  outline: none !important;
  border-color: none;
  box-shadow: 0 0 10px none;
}

input[type="text"].editable-cell {
  padding: 7px 10px;
  margin: 8px 0;
  border: 1px solid rgb(157, 157, 157);
}

input[type="text"]:focus.editable-cell {
  padding: 7px 10px;
  margin: 8px 0;
  outline: none !important;
  border-color: none;
  box-shadow: 0 0 10px none;
}

/* Custom HTML Select */
.custom-select {
  --radius: 0rem;
  --baseFg: #b5b5b5;
  --baseBg: #f2f2f2;
  --accentFg: white;
  --accentBg: #b5b5b5;
  --borderBg: #aeadad;
}

select {
  font: 400 12px/1.3 sans-serif;
  -webkit-appearance: none;
  appearance: none;
  color: #514f64;
  border: 1px solid var(--baseFg);
  line-height: 1;
  outline: 0;
  padding: 0.65em 2.5em 0.55em 0.75em;
  border-radius: var(--radius);
  background-color: var(--baseBg);
  background-image: linear-gradient(var(--baseFg), var(--baseFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(var(--accentBg) 42%, var(--accentFg) 42%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
  background-position: right 20px center, right bottom, right bottom,
    right bottom;
}

select:hover {
  background-image: linear-gradient(var(--accentFg), var(--accentFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
}

select:active {
  background-image: linear-gradient(var(--accentFg), var(--accentFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
  border-color: var(--borderBg);
  background-color: var(--accentFg);
}

/* Custom Input */
.custom-input {
  --radius: 0rem;
  --baseFg: #b5b5b5;
  --baseBg: #e9e9e9;
  --accentFg: #cecece;
  --accentBg: #5e8cc9;
}

input[type="number"] {
  -webkit-appearance: none;
  appearance: none;
  color: #514f64;
  /* font-weight: 600; */
  border: 1px solid var(--baseFg);
  line-height: 1;
  outline: 0;
  padding: 0.65em 0.65em 0.55em 0.75em;
  border-radius: var(--radius);
  background-color: var(--baseBg);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
}

ul {
  margin: 0;
}

/* iframe {
  z-index: 1 !important;
} */

[dir="rtl"] .carousel-container {
  direction: ltr !important;
}

/* 
   By default we have body bottom margin 
   for footer, remove for student dashboard 
*/

body {
  margin-bottom: 0px !important;
}

.content {
  padding: 10px;
  border: 1px solid black;
  background-color: rgb(246, 246, 246);
}

.header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  margin-bottom: 2px;
}

.receipt-header {
  padding: 2px 10px;
  border-radius: 3px;
  border: 1px dotted black;
  font-weight: 500;
}

.school-title {
  font-size: 16px;
  text-transform: uppercase;
}

.school-address {
  font-size: 12px;
}

.receipt-date {
  margin-top: 2px;
}

.student-info {
  margin-top: 3px;
  margin-bottom: 3px;
}

.student-info p {
  font-weight: 500;
  margin-top: 2px;
}

.footer {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.footer-amount {
  padding: 0px 10px;
  border-radius: 3px;
  border: 1px dotted black;
  font-weight: 500;
  display: flex;
}

.footer-authorized {
  padding-top: 10px;
}

/* @media print {
  @page {
    size: a4 landscape;
    margin: 0mm !important;
  }

  @media all {
    .pagebreak {
      overflow: visible;
    }
  }
} */

table.intimate,
td.intimate,
th.intimate {
  border: 1px solid;
}

table.intimate {
  width: 100%;
  border-collapse: collapse;
  margin: 6px 0px;
}

.school-detail {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
