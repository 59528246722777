/* Import Poppins font  */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* Import Montserrat font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f6 !important;
  margin: 0 0 40px 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#dateInputGroup {
  display: inherit;
}

div#dateInputGroup:hover {
  border-color: 1px olid red;
}

button#resetDateButton {
  margin-top: 8px;
  height: 35px;
  margin-left: -6px;
  color: rgb(102, 100, 100);
}

/* Scrollbar */

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 4px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars. 
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.4); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

/* Input type color CSS modified */
/* .input-color-container {
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border: solid 2px #ddd;
  border-radius: 40px;
}

.input-color {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 56px;
  height: 56px;
  border: none;
}

.input-color-label {
  cursor: pointer;
  text-decoration: underline;
  color: #3498db;
} */

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 45px;
  height: 32px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: 1px solid rgb(180, 177, 177);
}
input[type="text"].editable {
  background-color: #e0dedd;
  border: none;
  width: 100px;
  -webkit-transition: width 0.35s ease-in-out;
  transition: width 0.35s ease-in-out;
}
input[type="text"].editable:focus {
  outline: none;
  border: 1px dotted black;
}

input[type="number"].editable-cell {
  padding: 7px 10px;
  margin: 8px 0;
  border: 1px solid rgb(157, 157, 157);
}

input[type="number"]:focus.editable-cell {
  padding: 7px 10px;
  margin: 8px 0;
  outline: none !important;
  border-color: none;
  box-shadow: 0 0 10px none;
}

input[type="text"].editable-cell {
  padding: 7px 10px;
  margin: 8px 0;
  border: 1px solid rgb(157, 157, 157);
}

input[type="text"]:focus.editable-cell {
  padding: 7px 10px;
  margin: 8px 0;
  outline: none !important;
  border-color: none;
  box-shadow: 0 0 10px none;
}

/* Custom HTML Select */
.custom-select {
  --radius: 0rem;
  --baseFg: #b5b5b5;
  --baseBg: #f2f2f2;
  --accentFg: white;
  --accentBg: #b5b5b5;
  --borderBg: #aeadad;
}

select {
  font: 400 12px/1.3 sans-serif;
  -webkit-appearance: none;
  appearance: none;
  color: #514f64;
  border: 1px solid var(--baseFg);
  line-height: 1;
  outline: 0;
  padding: 0.65em 2.5em 0.55em 0.75em;
  border-radius: var(--radius);
  background-color: var(--baseBg);
  background-image: linear-gradient(var(--baseFg), var(--baseFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(var(--accentBg) 42%, var(--accentFg) 42%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
  background-position: right 20px center, right bottom, right bottom,
    right bottom;
}

select:hover {
  background-image: linear-gradient(var(--accentFg), var(--accentFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
}

select:active {
  background-image: linear-gradient(var(--accentFg), var(--accentFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
  border-color: var(--borderBg);
  background-color: var(--accentFg);
}

/* Custom Input */
.custom-input {
  --radius: 0rem;
  --baseFg: #b5b5b5;
  --baseBg: #e9e9e9;
  --accentFg: #cecece;
  --accentBg: #5e8cc9;
}

input[type="number"] {
  -webkit-appearance: none;
  appearance: none;
  color: #514f64;
  /* font-weight: 600; */
  border: 1px solid var(--baseFg);
  line-height: 1;
  outline: 0;
  padding: 0.65em 0.65em 0.55em 0.75em;
  border-radius: var(--radius);
  background-color: var(--baseBg);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
}

ul {
  margin: 0;
}

/* iframe {
  z-index: 1 !important;
} */

[dir="rtl"] .carousel-container {
  direction: ltr !important;
}
